import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  save (data) {
    var d_uuid = 'new';
    if (data.id) {
      d_uuid = data.id;
    }
    let url = API_BASE_URL + 'patient/' + data.patient_id + '/medicine_save/' + d_uuid;
    return Vue.http.put(url, data);
  },
  medicine_operation_patient_applied (data) {
    let url = API_BASE_URL + 'patient/' + data.patient_id + '/medicine_operation/patient_applied/' + data.id;
    return Vue.http.put(url, data);
  },
  medicine_operation_patient_didnot_apply (data) {
    let url = API_BASE_URL + 'patient/' + data.patient_id + '/medicine_operation/patient_didnot_apply/' + data.id;
    return Vue.http.put(url, data);
  },
  medicine_operation_delete (data) {
    let url = API_BASE_URL + 'patient/' + data.patient_id + '/medicine_operation/delete_patient_application/' + data.id;
    return Vue.http.put(url, data);
  },
  search (data) {
    return Vue.http.post(API_BASE_URL + 'patient/' + data.patient_id + '/medicines/list');
  },
  get_chemotherapy_medicine_list (data) {
    return Vue.http.post(API_BASE_URL + 'patient/get_chemotherapy_medicine_list', data);
  },
  get (puid, uuid) {
    return Vue.http.get(API_BASE_URL + 'patient/' + puid + '/medicines/' + uuid);
  },
  delete (puid, uuid) {
    return Vue.http.delete(API_BASE_URL + 'patient/' + puid + '/medicine_delete/' + uuid);
  }
};
